import { v4 as uuid } from 'uuid'
import { hash } from 'ohash'

/**
 * This file exposes generators.
 *
 * These functions are auto-imported in components and pages and can be used
 * directly in templates, <script setup> or anywhere in the Options API without
 * having to manually import them.
 */

/**
 * Generates a Random UUID (Universally Unique Identifier).
 *
 * @returns {string} A string representation of a UUID.
 *
 * @example
 * const id = generateUuid();
 * console.log(id); // Outputs: "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed"
 */
export function generateUuid(): string {
  return uuid()
}

/**
 * Creates a unique request key by hashing a value and prepending it with a constant string.
 *
 * @param {string} constString - The constant string to prepend to the hashed value.
 * @param {string} valueToHash - The value to be hashed.
 * @returns {string} A string that combines the constant string and the hashed value.
 *
 * @example
 * const requestKey = createRequestKey("REQ", "valueToHash");
 * console.log(requestKey); // Outputs: "REQ1234567890"
 */
export function createRequestKey(constString: string, valueToHash: string): string {
  return `${constString}${hash(valueToHash)}`
}
